//react-photoswipe-gallery
.pswp__counter{
    display: none !important; 
}
.pswp__button{
    display: flex !important;
    align-items: center;
    justify-content: center;
    &::before{
        font-family: 'Material Symbols Outlined';
        font-size: 36px;
        color: #fff;
    }
}
.pswp__button--close{
    svg{
        display: none;
    }
    &::before{
        content: '\e5cd';
    }
}
.pswp__button--zoom{
    svg{
        display: none;
    }
    &::before{
        content: '\e8ff';
    }
}
.pswp--zoomed-in{
    .pswp__button--zoom{
        &::before{
            content: '\e900';
        }
    }
}
.pswp__button--arrow{
    cursor: pointer;
    svg{
        display: none;
    }
    
    &.pswp__button--arrow--prev{
        &::before{
            content: '\e5e0';
        }
    }
    &.pswp__button--arrow--next{
        &::before{
            content: '\e5e1';
        }
    }
}
.pswp__top-bar{
    .pswp__button{
        margin-left: 10px;
    }
    .pswp__button--select-template{
        white-space: nowrap;
        width: auto !important;
        opacity: 1;
    }
}
.pswp__top-bar{
    @media (max-width: $tablet-size){
        background: rgba(0,0,0,.5) !important;
    }
}