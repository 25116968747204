.page-template{
    .template-nav{
        padding-top: 40px;
        background: #fff;
        @media (max-width: $mobile-size-l){
            overflow-x: auto;
            overflow-y: hidden;
        }
        ul{
            display: flex;
            height: 60px;
            @media (max-width: $tablet-size){
                padding: 0;
            }
            li{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 500;
                padding: 0 16px;
                opacity: .5;
                user-select: none;
                white-space: nowrap;
                cursor: pointer;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    background: $color-primary;
                    opacity: 0;
                    border-radius: 6px;
                }
                &:hover{
                    opacity: 1;
                }
                &.current{
                    opacity: 1;
                    &::before{
                        opacity: 1;
                    }
                }

            }
        }
    }
    .template-main{
        padding-top: 50px;
        padding-bottom: 20px;
        @media (max-width: $tablet-size){
            padding-top: 40px;
            padding-bottom: 0;
        }
    }
    .template-title{
        margin-bottom: 10px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 22px;
        
    }
    .template-list{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .template-item{
            position: relative;
            width: calc(25% - 12px);
            margin-bottom: 26px;
            margin-right: 16px;
            &:nth-child(4n):not(.mobile-item){
                margin-right: 0;
            }
            @media screen and (min-width: $mobile-size-l) and (max-width: $tablet-size){
                width: calc(33.333% - 10.666px);
                &:nth-child(4n):not(.mobile-item){
                    margin-right: 16px;
                }
                &:nth-child(3n):not(.mobile-item){
                   margin-right: 0;
                }
            }
            @media (max-width: $mobile-size-l){
                width: calc(50% - 8px);
                &:nth-child(4n):not(.mobile-item){
                    margin-right: 16px;
                }
                &:nth-child(2n):not(.mobile-item){
                   margin-right: 0;
                }
            
            }
            .template-name{
                font-size: 14px;
                margin-bottom: 5px;
                color: $color-text-light;
                text-transform: uppercase;
                .template-name-tip{
                    margin-top: 2px;
                    text-transform: capitalize;
                    line-height: 1.2;
                }
            }
            .template-cover{
                position: relative;
                margin-bottom: 8px;
                border: 1px solid #e8e8e8;
                border-radius: 6px;
                overflow: hidden;
                cursor: pointer;
                &::before{
                    content: '';
                    float: left;
                    width: 100%;
                    padding-bottom: 80%;
                }
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: top center;
                }
                .link-cover{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(#000,.8);
                    pointer-events: none;
                    opacity: 0;
                    transition: .3s;
                    .k-btn{
                        width: 80%;
                    }
                    @media (max-width: $tablet-size){
                        display: none;
                    }
                }
                &:hover{
                    .link-cover{
                        opacity: 1;
                    }
                }

            }
            .template-info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .k-btn{
                    transition: .3s;
                    &:hover{
                        transform: scale(1.05);
                    }
                }
                .btn-preview{
                    flex: 0 0 40px;
                    padding: 0;
                    @media (min-width: $tablet-size){
                        display: none;
                    }
                }
                .btn-select{
                    flex: 0 0 100%;
                    @media (max-width: $tablet-size){
                        flex: 0 0 calc(100% - 40px - 6px);
                    }
                }
            }
            &.mobile-item{
                width: calc(20% - 12.8px);
                &:nth-child(5n){
                    margin-right: 0;
                }
                .template-cover::before{
                    padding-bottom: 178%;
                }
                @media screen and (min-width: $mobile-size-l) and (max-width: $tablet-size){
                    width: calc(33.333% - 10.666px);
                    &:nth-child(5n){
                        margin-right: 16px;
                    }
                    &:nth-child(3n){
                       margin-right: 0;
                    }
                }
                @media (max-width: $mobile-size-l){
                    width: calc(50% - 8px);
                    &:nth-child(5n){
                        margin-right: 16px;
                    }
                    &:nth-child(2n){
                       margin-right: 0;
                    }
                }
            }
            &.current{
                &::after{
                    content: '\e5ca';
                    font-family: 'Material Symbols Outlined';
                    position: absolute;
                    top: 5px;
                    right: -10px;
                    width: 30px;
                    height: 30px;
                    z-index: 2;
                    background: $color-primary;
                    color: #fff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                }
                .template-cover{
                    border: 3px solid #000;
                }
            }
            &.is-disabled{
                .template-cover{
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}
.template-form{
    position: fixed;
    height: calc(100% - 80px);
    width: 350px;
    right: 40px;
    bottom: 0;
    z-index: index($z-index, temp-form);
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,0.15);
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transform: translateY(calc(100% - 50px));
    transition: .25s ease;
    @media (max-width: $tablet-size){
        width: 100%;
        border-radius: 0;
        right: 0;
        height: calc(100% - 60px);
    }
    &.active{
        transform: none;
    }
    .form-header{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-primary;
        height: 50px;
        color: #fff;
        flex: 0 0 50px;
        .form-header-title{
            font-size: 18px;
        }
        .btn-toggle{
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            cursor: pointer;
            &:hover{
                opacity: .5;
            }
        }
    }
    .form-body{
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 16px;
    }
    .form-footer{
        padding: 10px;
        flex: 0 0 60px;
        border-top: 1px solid #dadce0;
    }
    .form-block{
        margin-bottom: 20px;
        .form-main-title{
            margin-bottom: 6px;
            font-weight: 600;
        }
        .form-block-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .form-title{
                font-weight: 600;
            }
            .btn-toggle{
                user-select: none;
                cursor: pointer;
            }
        }
        .form-block-content{
            height: 0;
            overflow: hidden;
        }
        &.active{
            .form-block-content{
                height: auto;
                overflow: visible;
            }

        }
    }
    p{
        color: $color-text-lighter;
        font-size: 14px;
        line-height: 1.4;
    }
    .form-pic-info{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .pic{
            position: relative;
            border-radius: 6px;
            width: 70px;
            overflow: hidden;
            user-select: none;
            &::before{
                content: '';
                float: left;
                width: 100%;
                padding-bottom: 100%;
                background: #f5f7f9;
            }
            .no-pic{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-size: 32px;
                opacity: .1;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: top center;
            }
        }
        .right{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;
            width: calc(100% - 70px);
            .info-title{
                display: flex;
                flex-direction: column;
                gap: 6px;
                font-weight: 600;
                font-size: 14px;
                .selected{
                    font-weight: 400;
                    color: $color-text-lighter;
                }
                .disabled-tip{
                    font-weight: 400;
                    color: $color-text-lighter;
                    font-size: 12px;
                    line-height: 1.2;
                    padding-right: 20px;
                }
            }
            .k-btn{
                white-space: nowrap;
            }
        }
        &.is-disabled{
            .pic{
                opacity: .5;
            }
            cursor: not-allowed;
            .k-btn{
                cursor: not-allowed;
                opacity: .5;
                background: #eee;
            }
        }
    }
    .k-react-select,
    .k-input{
        margin-bottom: 10px;
    }
    .input-title{
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
    }

}
