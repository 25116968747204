.k-header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: index($z-index, header);
    width: 100%;
    height: 60px;
    background: #fff;
    .k-header-inner{
        height: 60px;
        display: flex;
        align-items: center;
        >*{
            display: flex;
            align-items: center;
            gap: 10px;
            @media (max-width: $desktop-size){
                gap: 8px
            }
        }
        .left{
            .k-logo{
                margin-right: 24px;
                height: 36px;
                cursor: pointer;
            }
        }
        .center{
            .k-navigation{
                ul{
                    display: flex;
                    gap: 15px;
                    height: 100%;
                    li{
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        padding: 0 8px;
                        height: 36px;
                        font-size: 15px;
                        user-select: none;
                        cursor: pointer;
                        line-height: 1.2;
                        &.current{
                            font-weight: bold;
                        }
                    }
                }
                @media (max-width: $desktop-size){
                    display: none;
                }
            }
        }
        .right{
            margin-left: auto;
            .btn-contact{
                @media (max-width: $desktop-size){
                    display: none !important;
                }
            }
            .k-btn{
                white-space: nowrap;
            }
            .lang-select{
                position: relative;
                .lang-toggle{
                    display: flex;
                    align-items: center;
                    width: 24px;
                    font-size: 14px;
                    >img{
                        margin-right: 6px;
                        width: 25px;
                        border-radius: 50%;
                    }
                    >span {display: none;}
                    >i{
                        margin-left: auto;
                    }
                }
                >select{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
                @media (max-width: $desktop-size){
                    .lang-toggle{
                        width: 25px;
                        >i,
                        >span{
                            display: none;
                        }
                    }
                }
            }
            .btn-menu{
                cursor: pointer;
                i{
                    font-size: 30px;
                }
                @media (min-width: $desktop-size){
                    display: none;
                }
            }
        }
    }
    .mobile-menu{
        position: fixed;
        top: 60px;
        left: 100%;
        padding: 16px 20px;
        background: #fff;
        width: 100%;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: left .3s;
        border-top: 1px solid #eee;
        .k-navigation{
            ul{
                display: flex;
                flex-direction: column;
                li{
                    padding: 12px 0;
                    font-size: 20px;
                    text-align: center;
                    user-select: none;
                    cursor: pointer;
                    &.current{
                        font-weight: bold;
                    }
                }
            }
        }
        .highlight-btn{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        &.open{
            left: 0;
        }
        @media (min-width: $desktop-size){
            left: 100% !important;
        }
    }

}