.home-container{
    position: relative;
    background: #fff;
    min-height: calc( var(--window-inner-height, 100vh) - env(safe-area-inset-bottom));
    z-index: index($z-index, container);
    padding-top: 60px;
    .main-wrap{
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
}
.page-home{
    @media (max-width:$desktop-size){
        padding-top: 10px;
    }
    @media (max-width: $tablet-size){
        padding-top: 20px;
    }
    >.k-btn-toggle-group{
        margin-bottom: -40px;
        display: none;
        @media (max-width:$desktop-size){
            display: flex;
        }
    }
    .home-top{
        background-size: auto 70%;
        background-position: 110% 50%;
        background-repeat: no-repeat;
        @media (max-width: $desktop-size){
            margin-bottom: 40px;
            padding: 40px 0;
            height: auto;
            min-height: calc(100vh - 60px);
        }
        .main-wrap{
            display: flex;
            align-items: center;
            min-height: calc(100vh - 60px);
            padding: 40px 0 60px 0;
            @media (max-width: $desktop-size){
             flex-direction: column;   
            }
        }
        .left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-right: 40px;
            width: 45%;
            @media (max-width: $desktop-size){
                margin-bottom: 40px;
                padding-right: 0;
                width: 100%;
            }
            .small-text-tag{
                margin-bottom: 20px;
                padding: 10px 20px;
                border-radius: 50px;
                background: #f5f6ff;
                font-size: 15px;
                font-weight: 500;
            }
            >h1{
                margin-bottom: 30px;
                font-size: 60px;
                font-weight: bold;
                line-height: 1;
                @media (max-width: $mobile-size-l){
                    font-size: 50px;
                }
            }
            >p{
                margin-bottom: 30px;
                line-height: 1.5;
                color: $color-text-light;
                font-weight: 400;
                font-size: 15px;
                @media (max-width: $mobile-size-l){
                    font-size: 14px;
                }
            }
            .button-group{
                display: flex;
                align-items: center;
                gap: 20px;
                >*{
                    min-width: 130px;
                }
                @media (max-width: $desktop-size){
                    width: 80%;
                    margin: 0 auto;
                    >*{
                        flex: 1;
                        min-width: unset;
                    }
                }
            }
        }
        .right{
            width: 55%;
            @media (max-width: $desktop-size){
                width: 80%;
            }
            @media (max-width: $mobile-size-l){
                width: 90%;
            }
            >img,
            >video{
                display: block;
                width: 100%;
            }
            >video {
                border-radius: 10px;
                -webkit-box-reflect: below 2px linear-gradient(to bottom,  rgba(0,0,0,0) 50%,  rgba(0,0,0,0.2) 100%);
            }
        }
    }
    .home-partner{
        margin-bottom: 60px;
        text-align: center;
        .home-partner-title{
            margin-bottom: 20px;
            color: $color-text-light;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.4;
            @media (max-width: $desktop-size){
                margin: 0 auto 20px;
            }
        }
        .home-partner-item{
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            img{
                display: block;
                max-width: 80%;
                max-height: 100%;
                filter: grayscale(100%);
            }
        }
    }
    .home-feature{
        position: relative;
        min-height: calc(100vh - 60px);
        background: #000;
        >video{
            position: absolute;
            z-index: 1;
            object-fit: cover;
            width:100%;
            height:100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &::-webkit-media-controls {
                display:none !important;
            }
        }
        .video-mask{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            backdrop-filter: grayscale(100%) brightness(20%);
        }
        .main-wrap{
            position: relative;
            z-index: 3;
            color: #fff;
            padding: 80px 0;
        }
        .feature-title{
            text-align: center;
            font-size: 40px;
            font-weight: bold;
            width: 80%;
            margin: 0 auto 20px;
            @media (max-width: $tablet-size){
                width: 100%;
            }
        }
        .feature-desc{
            width: 85%;
            margin: 0 auto 40px;
            text-align: center;
            opacity: 0.8;
            line-height: 1.4;
            @media (max-width: $tablet-size){
                width: 100%;
            }
        }
        .feature-list{
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            @media (max-width: $desktop-size){
                width: 100%;
                margin: 0 auto;
            }
            @media (max-width: $mobile-size-l){
                gap: 20px;
            }
            .feature-item{
                padding: 20px;
                width: calc(25% - 22.5px);
                background: #1e1e1e;
                border-radius: 20px;
                @media (max-width: $desktop-size){
                    width: calc(33.333% - 20px);
                }
                @media (max-width: $tablet-size){
                    width: calc(50% - 15px);
                }
                @media (max-width: $mobile-size-l){
                    width: calc(50% - 10px);
                }
                img{
                    margin-bottom: 15px;
                    display: block;
                    width: 40%;
                    opacity: .6;
                }
                >h4{
                    margin-bottom: 10px;
                    font-weight: 500;
                    line-height: 1.4;
                }
                >p{
                    font-size: 13px;
                    opacity: 0.8;
                    line-height: 1.6;
                }
            }

        }
    }
    .home-about{
        padding: 80px 0;
        text-align: center;
        .about-title{
            margin-bottom: 20px;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.2;
        }
        .about-desc{
            margin-bottom: 30px;
            color: $color-text-light;
            line-height: 1.6;
            font-weight: 400;
            @media (max-width: $tablet-size){
                margin-bottom: 50px;
            }
        }
        .about-list{
            display: flex;
            gap: 90px;
            @media (max-width: $desktop-size){
                gap: 40px;
            }
            @media (max-width: $tablet-size){
                flex-direction: column;
            }
            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                .item-img{
                    margin-bottom: 10px;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                .item-title{
                    margin-bottom: 10px;
                    font-weight: 500;
                    line-height: 1.4;
                }
                .item-desc{
                    font-size: 13px;
                    color: $color-text-light;
                    line-height: 1.6;
                    text-align: left;
                }
                @media (max-width: $tablet-size){
                    flex-direction: row;
                    align-items: flex-start;
                    text-align: left;
                    .item-img{
                        width: 20%;
                        height: auto;
                    }
                    .item-main-content{
                        padding-left: 10px;
                        width: calc(100% - 100px);
                    }
                }
                @media (max-width: $mobile-size-l){
                    .item-img{
                        width: 25%;
                    }
                }
            }
        }
    }
    .home-invite{
        position: relative;
        padding: 80px 0;
        color: #fff;
        background-color: #000;
        >video{
            position: absolute;
            z-index: 1;
            object-fit: cover;
            width:100%;
            height:100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &::-webkit-media-controls {
                display:none !important;
            }
        }
        .video-mask{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            backdrop-filter: grayscale(100%) brightness(20%);
        }
        .main-wrap{
            position: relative;
            z-index: 3;
            display: flex;
            @media (max-width: $desktop-size){
                flex-direction: column;
                gap: 10px;
                text-align: center;
            }
            >*{
                flex: 1;
            }
            >h3{
                font-size: 40px;
                font-weight: bold;
                line-height: 1.2;
            }
            >p{
                font-size: 14px;
                line-height: 1.6;
            }
        }
    }
    .home-demo{
        padding: 80px 0;
        .home-demo-title{
            width: 80%;
            margin: 0 auto 20px;
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            @media (max-width: $desktop-size){
                width: 100%;
            }
        }
        .home-demo-desc{
            width: 80%;
            margin: 0 auto 40px;
            text-align: center;
            color: $color-text-light;
            line-height: 1.6;
            font-weight: 400;
            @media (max-width: $desktop-size){
                width: 100%;
            }
        }
        .home-demo-main{
            position: relative;
            margin-bottom: 10px;
            @media (max-width: $desktop-size){
                margin-bottom: 0;
            }
            .mobile-frame{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: $desktop-size){
                    position: relative;
                    transform: unset;
                    top: unset;
                    left: unset;
                    margin: 0 auto 40px;
                }
                @media (max-width: $tablet-size){
                    width: 60%;
                }
                >img{
                    display: block;
                    width: 100%;
                }
                >video{
                    position: absolute;
                    top: 50%;
                    left: 4%;
                    width: 92%;
                    transform: translateY(-50%);
                }
            }
            .home-demo-list{
                z-index: 2;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                @media (max-width: $desktop-size){
                    gap: 20px;                    
                }
                .demo-item{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 20px;
                    width: 50%;
                    @media (max-width: $desktop-size){
                        width: calc(50% - 10px);
                        margin-bottom: 0;
                    }
                    &:nth-child(even){
                        text-align: right;
                        align-items: flex-end;
                    }
                    .main-content{
                        width: 60%;
                        padding: 20px;
                        border-radius: 20px;
                        border: 2px solid #f5f6ff;
                        @media (max-width: $desktop-size){
                            width: 100%;
                        }
                        >h4{
                            margin-bottom: 10px;
                            font-weight: 500;
                            line-height: 1.4;
                        }
                        >p{
                            font-size: 13px;
                            color: $color-text-light;
                            line-height: 1.6;
                        }
                    }
                }
            }

        }
    }
    .home-press{
        padding: 80px 0;
        .home-press-title{
            width: 80%;
            margin: 0 auto 40px;
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            @media (max-width: $desktop-size){
                width: 100%;
            }
        }
        .home-news-main{
            position: relative;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            @media (max-width: $desktop-size){
                margin-bottom: 0;
            }
            
            .home-news-list {
                flex: 0 0 32%;
                padding: 20px 20px 10px;
                border-radius: 20px;
                box-sizing: border-box;
                box-shadow: 0 0 0 2px #f5f6ff inset;
                &:hover {
                    background: #f5f6ff;
                }
                h3 {
                    font-size: 21px;
                    font-weight: bold;
                    margin-bottom: 10px;
                    height: 65px;
                }
                .home-news-src {
                    display: flex;
                    align-items: center;
                    gap:5px;
                    img {
                        border-radius: 50%;
                        height: 20px;
                    }
                }
                .home-news-kv {
                    width: 100%;
                    height: 200px;
                    overflow: hidden;
                    margin: 10px auto;
                    border-radius: 5px;
                    img {
                        max-width: 100%;
                        
                    }
                }
            }
            @media (max-width: $mobile-size-l){
                flex-wrap: wrap;
                .home-news-list {
                    flex: 0 0 100%;
                    margin-bottom: 10px;
                    .home-news-kv {
                        height: 40vw;
                    }
                }
            }
        }
        .button-group {
            button {
                margin: auto;
            }
        }
    }
    .home-contact{
        .home-contact-main{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px;
            background: #f5f6ff;
            border-radius: 20px;
            @media (max-width: $desktop-size){
                padding: 30px;
            }
            >h3{
                margin: 0 auto;
                width: 80%;
                text-align: center;
                font-size: 36px;
                font-weight: bold;
                line-height: 1.2;
                @media (max-width: $desktop-size){
                    width: 90%;
                }
                @media (max-width: $mobile-size-l){
                    width: 100%;
                }
            }
            >p{
                margin: 0 auto;
                width: 85%;
                padding: 40px 0;
                text-align: center;
                line-height: 1.6;
                color: $color-text-light;
                @media (max-width: $desktop-size){
                    width: 90%;
                }
                @media (max-width: $mobile-size-l){
                    padding: 30px 0;
                }
            }
            .button-group{
                display: flex;
                gap: 10px;
                .k-btn{
                    min-width: 150px;
                }
                @media (max-width: $desktop-size){
                    width: 80%;
                    margin: 0 auto;
                    .k-btn{
                        flex: 1;
                        min-width: unset;
                    }
                }
                @media (max-width: $mobile-size-l){
                    width: 100%;
                }
            }
        }
    }
}