$textDur: 1050ms;
$waveDur: 2100ms;
.page-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: index($z-index, page-loading);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    p {
        position: relative;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 1px;
        overflow: hidden;
        background: linear-gradient(90deg, #fff, $color-primary, #fff);
        background-repeat: no-repeat;
        background-size: 80%;
        animation: animate 3s linear infinite;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.1);
    }
    .desc{
        padding: 18px 0;
        color: $color-text-lighter;
        line-height: 1.4;
    }
    @keyframes animate {
    0% {
        background-position: -500%;
    }
    100% {
        background-position: 500%;
    }
    }
}