$color-primary: #232320;
$color-text: #0d0c22;
$color-text-light: #383838;
$color-text-lighter: #9e9e9e;
$color-input: #f5f7f9;
$color-error: #EA1448;
$color-success: #9DC811;
$color-warning: #FFC700;
$z-index: container, header, temp-form, page-loading;
$desktop-size: 1140px;
$tablet-size: 768px;
$mobile-size-l: 435px;
