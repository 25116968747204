.page-sports{
    @media (max-width:$desktop-size){
        padding-top: 10px;
    }
    @media (max-width: $tablet-size){
        padding-top: 20px;
    }
    >.k-btn-toggle-group{
        margin-bottom: 20px;
        display: none;
        @media (max-width:$desktop-size){
            display: flex;
        }
        @media (max-width: $tablet-size){
            margin-bottom: 0;
        }
    }
    // .sports-top{
    //     position: relative;
    //     background: linear-gradient(to bottom, #fff 20%,#f6f6f6);
    //     &::after{
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         z-index: 2;
    //         width: 100%;
    //         height: 100%;
    //         background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #f6f6f6);
    //     }
    //     .main-wrap{
    //         position: relative;
    //         z-index: 3;
    //         padding: 40px 0;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         justify-content: center;
    //         min-height: calc(100vh - 60px);
    //         width: 650px;
    //         @media (max-width: $desktop-size){
    //             width: 50%;
    //         }
    //         @media (max-width:$tablet-size){
    //             align-items: flex-start;
    //             margin: 0 0 0 7%;
    //             min-height: unset;
    //         }
    //         @media (max-width: $mobile-size-l){
    //             width: 60%;
    //             padding-bottom: 50px;
    //         }
    //     }
    //     .small-text-tag{
    //         margin-bottom: 20px;
    //         padding: 10px 20px;
    //         border-radius: 50px;
    //         background: #f5f6ff;
    //         font-size: 15px;
    //         font-weight: 500;
    //         transition: font-size .3s;
    //         @media (max-width: $tablet-size){
    //             font-size: 12px;
    //         }
    //     }
    //     h1{
    //         margin-bottom: 30px;
    //         font-size: 64px;
    //         font-weight: bold;
    //         line-height: 1.2;
    //         text-align: center;
    //         max-width: 100%;
    //         transition: font-size .3s;
    //         text-transform: capitalize;
    //         strong{
    //             display: block;
    //         }
    //         @media (max-width: $tablet-size){
    //             font-size: 32px;
    //             max-width: unset;
    //             text-align: left;
    //             line-height: 1.2;
    //             strong{
    //                 font-size: 44px;
    //                 margin-bottom: 8px;
    //             }
    //         } 
    //         @media (max-width: $mobile-size-l){
    //             font-size: 26px;
    //             margin-bottom: 10px;
    //             strong{
    //                 font-size: 34px;
    //             }
    //         }
            
    //     }
    //     p{
    //         margin-bottom: 30px;
    //         line-height: 1.5;
    //         color: $color-text-light;
    //         font-weight: 400;
    //         text-align: center;
    //         max-width: 600px;
    //         @media (max-width: $desktop-size){
    //             text-align: left;
    //             display: none;
    //         }
    //         @media (max-width: $mobile-size-l){
    //             font-size: 14px;
                
    //         }
    //     }
    //     p.desc-mobile{
    //         display: none;
    //         @media (max-width: $desktop-size){
    //             display: block;
    //         }
    //     }
    //     .button-group{
    //         display: flex;
    //         align-items: center;
    //         gap: 20px;
    //         >*{
    //             min-width: 130px;
    //         }
    //         @media (max-width: $tablet-size){
    //             width: 50%;
    //             >*{
    //                 flex: 1;
    //                 min-width: unset;
    //             }
    //         }
    //     }
    //     .kv-right-mobile,
    //     .kv-right,
    //     .kv-left{
    //         position: absolute;
    //         z-index: 1;
    //         top: 50%;
    //         transform: translateY(-50%);
    //         height: 100%;
    //         width: calc( ( 100% - 600px - 100px ) / 2);
    //         overflow: hidden;
    //         transition: all .3s;
    //         @media (max-width: $desktop-size){
    //             width: calc( ( 100% - 70% + 100px ) / 2);
    //         }
    //         img{
    //             position: absolute;
    //             top: 0;
    //             height: 90%;
    //             @media (max-width: $desktop-size){
    //                 height: 70%;
    //             }
    //         }
    //     }
    //     .kv-right{
    //         right: 0;
    //         img{
    //             left: 0;
    //         }
    //         @media (max-width: $tablet-size){
    //             opacity: 0;
    //         }
    //     }
    //     .kv-left{
    //         left: 0;
    //         img{
    //             right: 0;
    //         }
    //         @media (max-width: $tablet-size){
    //             opacity: 0;
    //         }
    //     }
    //     .kv-right-mobile{
    //         right: 0;
    //         opacity: 0;
    //         img{
    //             left: 0;
    //             top: 50%;
    //             transform: translateY(-50%);
    //         }
    //         @media (max-width: $tablet-size){
    //             opacity: 1;
    //             width: 40%;
    //         }
    //         @media (max-width: $mobile-size-l){
    //             width: 30%;
    //         }
    //     }
    // }
    .sports-top{
        position: relative;
        background: linear-gradient(to bottom, #fff 20%,#f6f6f6);
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #f6f6f6);
        }
        .main-wrap{
            position: relative;
            z-index: 3;
            padding: 40px 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 75%;
            max-width: 1100px;
            @media (max-width: $desktop-size){
                width: 70%;
            }
            @media (max-width:$tablet-size){
                width: 50%;
            }
            @media (max-width: $mobile-size-l){
                width: 85%;
                margin: auto;
            }
        }
        .small-text-tag{
            margin-bottom: 20px;
            padding: 10px 20px;
            border-radius: 50px;
            background: #f5f6ff;
            font-size: 15px;
            font-weight: 500;
            transition: font-size .3s;
            @media (max-width: $tablet-size){
                font-size: 12px;
            }
        }
        h1{
            margin-bottom: 30px;
            font-size: 64px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            max-width: 100%;
            transition: font-size .3s;
            text-transform: capitalize;
            strong{
                display: block;
            }
            @media (max-width: $tablet-size){
                font-size: 32px;
                max-width: unset;
                line-height: 1.2;
                strong{
                    font-size: 44px;
                    margin-bottom: 8px;
                }
            } 
            @media (max-width: $mobile-size-l){
                font-size: 26px;
                margin-bottom: 10px;
                strong{
                    font-size: 34px;
                }
            }
        }
        p{
            margin-bottom: 30px;
            line-height: 1.5;
            color: $color-text-light;
            font-weight: 400;
            text-align: center;
            max-width: 600px;
            @media (max-width: $tablet-size){
                text-align: left;
                display: none;
            }
            @media (max-width: $mobile-size-l){
                font-size: 14px;
                
            }
        }
        p.desc-mobile{
            display: none;
            @media (max-width: $tablet-size){
                display: block;
            }
        }
        .button-group{
            display: flex;
            align-items: center;
            gap: 20px;
            >*{
                min-width: 130px;
            }
            @media (max-width: $tablet-size){
                width: 50%;
                >*{
                    flex: 1;
                    min-width: unset;
                }
            }
        }
        
        
        
        .kv-sp {
            width: 95%;
            max-width: 1280px;
            position: relative;
            margin:  -3vw auto 30px;
            z-index: 3;
            
            img {
                display: block;
                width: 100%;
            }
            @media (max-width: $mobile-size-l){
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
                img {
                    width: 150%;
                    margin-left: -25%;
                }
            }
        }
    }
    .sports-feature{
        background: #f6f6f6;
        .main-wrap{
            position: relative;
            z-index: 3;
            padding: 80px 0;
            @media (max-width: $tablet-size){
                padding: 40px 0;
                width: 100%;
            }
        }
        .sports-feature-title{
            margin: 0 auto 50px;
            font-size: 64px;
            font-weight: bold;
            text-align: center;
            line-height: 1.2;
            text-transform: capitalize;
            @media (max-width: $desktop-size){
                font-size: 48px;
            }
            @media (max-width: $tablet-size){
                width: 90%;
                font-size: 36px;
            }
        }
        .sports-feature-list{
            .feature-item{
                display: flex;
                align-items: center;
                gap: 5%;
                margin-bottom: 40px;
                @media (max-width: $tablet-size){
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 60px;
                }
                .feature-img{
                    width: 55%;
                    @media (max-width: $tablet-size){
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                .feature-content{
                    width: 45%;
                    @media (max-width: $tablet-size){
                        width: 80%;
                        text-align: center;
                    }
                    >h4{
                        margin-bottom: 5px;
                        font-weight: 500;
                        line-height: 1.4;
                        font-size: 24px;
                        @media (max-width: $desktop-size){
                            font-size: 16px;
                        }
                    }
                    >p{
                        font-size: 16px;
                        color: $color-text-light;
                        line-height: 1.6;
                        @media (max-width: $desktop-size){
                            font-size: 14px;
                        }
                    }
                }
                &:nth-child(even){
                    .feature-img{
                        order: 2;
                        @media (max-width: $tablet-size){
                            order: 1;
                        }
                    }
                    .feature-content{
                        order: 1;
                        @media (max-width: $tablet-size){
                            order: 2;
                        }
                    }

                }
            }
        }
    }
    .sports-temp{
        padding: 80px 0;
        text-align: center;
        @media (max-width: $tablet-size){
            padding: 40px 0;
        }
        @media (max-width: $mobile-size-l){
            padding: 20px 0;
        }

        .sports-temp-title{
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.4;
            transition: font-size .3s;
            text-transform: capitalize;
            @media (max-width: $desktop-size){
                font-size: 36px;
            }
            @media (max-width: $tablet-size){
                line-height: 1.2;
            }
        }
        .sports-temp-desc{
            margin-bottom: 40px;
            color: $color-text-light;
            line-height: 1.6;
            font-weight: 400;
            font-size: 18px;
            transition: font-size .3s;
            @media (max-width: $desktop-size){
                font-size: 16px;
                margin-bottom: 30px;
            }
        }
        .template-list{
            display: flex;
            justify-content: space-between;
            @media (max-width: $tablet-size){
                flex-direction: column;
            }
            >div{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                >img{
                    display: block;
                    width: 80%;
                    filter: drop-shadow(0 0 20px #d2d3de);
                    border-radius: 10px;
                    @media (max-width: $tablet-size){
                        margin-bottom: 20px;
                    }
                    @media (max-width: $mobile-size-l){
                        width: 95%;
                    }
                }
            }
        }

    }
}