@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Thin.otf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Ultra-Light.otf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Light.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Regular.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Medium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-SemiBold.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Black.otf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Heavy.otf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
//italic
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Thin-It.otf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-UltraLight-It.otf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Light-It.otf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Regular-It.otf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Medium-It.otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-SemiBold-It.otf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Bold-It.otf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Black-It.otf');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Biennale';
    src: url('~fonts/biennale/Biennale-Heavy-It.otf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}